import * as Yup from 'yup';

export const RECYCLER_PORTAL_APP_CODE = 'RPL009';

export const FULFILLMENT_CYCLES = [
  {
    id: 'MONTHLY',
    label: 'Monthly',
    value: 'MONTHLY',
  },
  {
    id: 'QUARTERLY',
    label: 'Quarterly',
    value: 'QUARTERLY',
  },
  {
    id: 'SEMIANNUALLY',
    label: 'Semi Annually',
    value: 'SEMIANNUALLY',
  },
  {
    id: 'ANNUALLY',
    label: 'Annually',
    value: 'ANNUALLY',
  },
];

export const WO_TYPES = [
  {
    id: 'EPR_LOOP_VENDORS',
    label: 'EPR loop vendors',
    value: false,
  },
  {
    id: 'MARKETPLACE_VENDORS',
    label: 'Marketplace vendors',
    value: true,
  },
];

export const filtersMapper = {
  deliveryToDate: 'To Date',
  deliveryFromDate: 'From Date',
  OrderDateTo: 'Order To Date',
  OrderDateFrom: 'Order From Date',
  Items: 'Items',
  Districts: 'Districts',
  states: 'States',
  status: 'Status',

  docTypes: 'Doc Type',
  docStatus: 'Doc Status',
  filterCriteria: 'Criteria',
  FulfillmentCycle: 'Fulfillment Cycle',
  WorkOrderInOutStatus: 'WO Type',
};

export const PAGE_CONFIG = {
  size: 20,
};

export const EXECUTION_MODE = [
  {
    label: 'Service Provider',
    value: 'SERVICE_PROVIDER',
  },
  {
    label: 'Internal',
    value: 'INTERNAL',
  },
];
export const pageOptions = [
  {
    id: 1,
    label: 20,
    value: 20,
  },
  {
    id: 2,
    label: 40,
    value: 40,
  },
  {
    id: 3,
    label: 50,
    value: 50,
  },
  {
    id: 4,
    label: 100,
    value: 100,
  },
];

export const WORK_ORDER_STATUS = [
  {
    label: 'In Progress',
    id: 'IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'Draft',
    id: 'DRAFT',
  },
  {
    label: 'Closed',
    id: 'CLOSED',
  },
];

export const WORK_ORDER_FULLFILMENT_STATUS = [
  {
    label: 'Pending',
    id: 'PENDING',
  },

  {
    label: 'Delivery Initiated',
    id: 'DELIVERY_INITIATED',
  },
];
// CREATED, PICKED_UP, DROPPED_OFF, APPROVED, DISPUTE,RESOLVED
export const DELIVERY_STATUS = {
  CREATED: { id: 'CREATED', displayText: 'Created', colors: 'green' },
  PICKED_UP: { id: 'PICKED_UP', displayText: 'Picked up', colors: 'green' },
  DRAFT: { id: 'DRAFT', displayText: 'Draft', colors: 'green' },
  DROPPED_OFF: {
    id: 'DROPPED_OFF',
    displayText: 'Dropped off',
    colors: 'green',
  },
  APPROVED: { id: 'APPROVED', displayText: 'Approved', colors: 'green' },
  UNALLOCATED: {
    id: 'UNALLOCATED',
    displayText: 'UnAllocated',
    colors: 'green',
  },
  PARTIALLY_ALLOCATED: {
    id: 'PARTIALLY_ALLOCATED',
    displayText: 'Partially Allocated',
    colors: 'green',
  },
  ALLOCATED: { id: 'ALLOCATED', displayText: 'Allocated', colors: 'green' },
  DISPUTED: { id: 'DISPUTED', displayText: 'Disputed', colors: 'green' },
  IN_REVIEW: { id: 'IN_REVIEW', displayText: 'In Review', colors: 'green' },
  IN_PROGRESS: { id: 'IN_PROGRESS', displayText: 'In Progress', colors: 'green' },
  IN_TRANSIT: { id: 'IN_TRANSIT', displayText: 'In Transit', colors: 'green' },
  NEW: { id: 'NEW', displayText: 'New', colors: 'green' },
};

// new delivery Statuses
export const NEW_DELIVERY_STATUS = {
  CREATED: { id: 'CREATED', displayText: 'Created', colors: 'green' },
  PICKED_UP: { id: 'PICKED_UP', displayText: 'Picked up', colors: 'green' },
  DROPPED_OFF: {
    id: 'DROPPED_OFF',
    displayText: 'Dropped off',
    colors: 'green',
  },
  APPROVED: { id: 'APPROVED', displayText: 'Approved', colors: 'green' },
  AUTO_ALLOCATION: {
    id: 'AUTO_ALLOCATION',
    displayText: 'Auto Allocation',
    colors: 'green',
  },
  PARTIALLY_ALLOCATED: {
    id: 'PARTIALLY_ALLOCATED',
    displayText: 'Partially Allocated',
    colors: 'green',
  },
  CERTIFICATE_GENERATION: {
    id: 'CERTIFICATE_GENERATION',
    displayText: 'Certificate Generation',
    colors: 'green',
  },
  KAM_REVIEW: {
    id: 'KAM_REVIEW',
    displayText: 'Kam Review',
    colors: 'green',
  },
  KAM_APPROVED: {
    id: 'KAM_APPROVED',
    displayText: 'Kam / Brand Approved',
    colors: 'green',
  },
  DISPUTED: { id: 'DISPUTED', displayText: 'Disputed', colors: 'green' },
  IN_REVIEW: { id: 'IN_REVIEW', displayText: 'In Review', colors: 'green' },
  IN_PROGRESS: { id: 'IN_PROGRESS', displayText: 'In Progress', colors: 'green' },
  IN_TRANSIT: { id: 'IN_TRANSIT', displayText: 'In Transit', colors: 'green' },
  NEW: { id: 'NEW', displayText: 'New', colors: 'green' },
};

// IN_PROGRESS, AT_RISK, UNPLANNED, MET, APPROVED, DRAFT, UNALLOCATED

export const WORK_ORDER_DISPLAY_STATUS = {
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    displayText: 'In progress',
    colors: 'green',
  },
  AT_RISK: { id: 'AT_RISK', displayText: 'At risk', colors: 'green' },
  UNPLANNED: { id: 'UNPLANNED', displayText: 'Unplanned', colors: 'green' },
  MET: { id: 'MET', displayText: 'Met', colors: 'green' },
  APPROVED: { id: 'APPROVED', displayText: 'Approved', colors: 'green' },
  DRAFT: { id: 'DRAFT', displayText: 'Draft', colors: 'green' },
  UNALLOCATED: {
    id: 'UNALLOCATED',
    displayText: 'Unallocated',
    colors: 'green',
  },
};

//APPROVED
export const INVOICE_DISPLAY_STATUS = {
  APPROVED: {
    id: 'APPROVED',
    displayText: 'Approved',
  },
  PARTIALLYPAID: {
    id: 'PARTIALLY_PAID',
    displayText: 'Partially Paid',
  },
  PAID: {
    id: 'PAID',
    displayText: 'Paid',
  },
  REJECTED: {
    id: 'REJECTED',
    displayText: 'Rejected',
  },
  OUTSTANDING: {
    id: 'OUTSTANDING',
    displayText: 'Outstanding',
  },
  DRAFT: {
    id: 'DRAFT',
    displayText: 'Draft',
  },
  ACCOUNTED: {
    id: 'ACCOUNTED',
    displayText: 'Accounted',
  },
};

// export const DELIVERY_STATUS_LIST = [
//   {
//     label: 'CREATED',
//     id: 'MET',
//     color: '#59e1ca'
//   },
//   {
//     label: 'APPROVED',
//     id: 'MET',
//     color: '#59e1ca'
//   }

// ];

export const WORK_ORDER_TYPE = [
  {
    label: 'Target',
    value: 'TARGET',
  },
  {
    label: 'Execution',
    value: 'EXECUTION',
  },
];

export const DOCUMENT_STATUSES = {
  IN_REVIEW: 'IN_REVIEW',
  REJECTED: 'REJECTED',
  NO_DOC: 'NO_DOC',
  APPROVED: 'APPROVED',
};

export const DOCUMENT_STATUS_LIST = [
  {
    label: 'All',
    id: 'ALL',
    color: null,
  },
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7A96F8',
  },
  {
    label: 'Rejected',
    id: 'REJECTED',
    color: '#ff7272',
  },
  {
    label: 'No doc',
    id: 'NO_DOC',
    color: '#ffcf8f',
  },
  {
    label: 'Pending',
    id: 'PENDING',
    color: '#ffcf8f',
  },
  {
    label: 'Approved',
    id: 'APPROVED',
    color: '#80DECA',
  },
];

export const SOUCRE_LOCATION_TYPE = [
  {
    label: 'Post-Consumer',
    id: 'Post-Consumer',
  },
  {
    label: 'Industrial',
    id: 'Industrial',
  },
  {
    label: 'ULBs',
    id: 'ULBs',
  },
  {
    label: 'Talukas',
    id: 'Talukas',
  },
  {
    label: 'Waste Pickers',
    id: 'Waste Pickers',
  },
];

export const USER_ROLES = [
  {
    id: 'Admin',
    role: 'Admin',
  },
  {
    id: 'Operations',
    role: 'Operations',
  },
  {
    id: 'Auditor',
    role: 'Auditor',
  },
];

export const DELIVERY_STATUS_LIST_SP = [
  {
    label: 'In Progress',
    id: 'IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'In Transit',
    id: 'IN_TRANSIT',
    color: '#7297ff',
  },
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Disputed',
    id: 'DISPUTED',
    color: '#ff7272',
  },
  {
    label: 'Approved',
    id: 'APPROVED',
    color: '#59e1ca',
  },
  {
    label: 'Allocated',
    id: 'ALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'UnAllocated',
    id: 'UNALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'Partially Allocated',
    id: 'PARTIALLY_ALLOCATED',
    color: '#7297ff',
  },
];

export const DELIVERY_STATUS_LIST_PRO = [
  {
    label: 'In Progress',
    id: 'IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'In Transit',
    id: 'IN_TRANSIT',
    color: '#7297ff',
  },
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Disputed',
    id: 'DISPUTED',
    color: '#ff7272',
  },
  {
    label: 'Approved',
    id: 'APPROVED',
    color: '#59e1ca',
  },
  {
    label: 'Allocated',
    id: 'ALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'UnAllocated',
    id: 'UNALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'Partially Allocated',
    id: 'PARTIALLY_ALLOCATED',
    color: '#7297ff',
  },
];

export const DELIVERY_STATUS_LIST_BRAND = [
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Disputed',
    id: 'DISPUTED',
    color: '#ff7272',
  },
  {
    label: 'Approved',
    id: 'APPROVED',
    color: '#59e1ca',
  },
];

export const MARKETPLACE_MONTH_CARDS_NAME = [
  {
    id: 3,
    label: 'Apr',
    value: 3,
  },
  {
    id: 4,
    label: 'May',
    value: 4,
  },
  {
    id: 5,
    label: 'Jun',
    value: 5,
  },
  {
    id: 6,
    label: 'Jul',
    value: 6,
  },
  {
    id: 7,
    label: 'Aug',
    value: 7,
  },
  {
    id: 8,
    label: 'Sep',
    value: 8,
  },
  {
    id: 9,
    label: 'Oct',
    value: 9,
  },
  {
    id: 10,
    label: 'Nov',
    value: 10,
  },
  {
    id: 11,
    label: 'Dec',
    value: 11,
  },
  {
    id: 0,
    label: 'Jan',
    value: 0,
  },
  {
    id: 1,
    label: 'Feb',
    value: 1,
  },
  {
    id: 2,
    label: 'Mar',
    value: 2,
  },
];

export const RECYL_ACKNOWLEDGMENT_DOCTYPE_OPTIONS = [
  {
    id: 1,
    label: 'Coprocessing',
    type: 'DROP_CO_PROCESSING_CERTIFICATE',
    name: 'Coprocessing Certificate',
    options: [
      {
        id: 'Coprocess1',
        label: 'Availaible',
        value: 'Coprocessing Availaible',
        certificateValue: true,
        certificateId: 1,
      },
      {
        id: 'Coprocess2',
        label: 'Not Availaible',
        value: 'Coprocessing Not Availaible',
        certificateValue: false,
        certificateId: 1,
      },
      {
        id: 'Coprocess3',
        label: 'Outdated',
        value: 'Coprocessing Outdated',
        certificateValue: undefined,
        certificateId: 1,
      },
    ],
  },
  {
    id: 2,
    label: 'Recycling',
    type: 'DROP_CERTIFICATE_OF_RECYCLING',
    name: 'Recycling Certificate',
    options: [
      {
        id: 'Recycle1',
        label: 'Availaible',
        value: 'Recycling Availaible',
        certificateValue: true,
        certificateId: 2,
      },
      {
        id: 'Recycle2',
        label: 'Not Availaible',
        value: 'Recycling Not Availaible',
        certificateValue: false,
        certificateId: 2,
      },
      {
        id: 'Recycle3',
        label: 'Outdated',
        value: 'Recycling Outdated',
        certificateValue: undefined,
        certificateId: 2,
      },
    ],
  },
  {
    id: 3,
    label: 'CPCB',
    type: 'DROP_CERTIFICATE_OF_CPCB',
    name: 'CPCB Certificate',
    options: [
      {
        id: 'CPCB1',
        label: 'Availaible',
        value: 'CPCB Availaible',
        certificateValue: true,
        certificateId: 3,
      },
      {
        id: 'CPCB2',
        label: 'Not Availaible',
        value: 'CPCB Not Availaible',
        certificateValue: false,
        certificateId: 3,
      },
      {
        id: 'CPCB3',
        label: 'Outdated',
        value: 'CPCB Outdated',
        certificateValue: undefined,
        certificateId: 3,
      },
    ],
  },
];

export const DELIVERIES_TYPE_OPTIONS = [
  {
    label: 'Marketplace',
    id: 'true',
    color: '#7297ff',
    filterKey: 'isMarketplaceOrder.equals',
  },
  {
    label: 'Recycler Portal',
    id: 'RPL009',
    color: '#7297ff',
    filterKey: 'appCode.equals',
  },
  {
    label: 'All',
    id: 'false',
    color: '#ff7272',
    filterKey: 'isMarketplaceOrder.equals',
  },
];

export const ASSIGNED_DELIVERY_STATUS_LIST = [
  // {
  //   label: 'Created',
  //   id: 'ASSIGNED_CREATED',
  //   color: '#7297ff'
  // },
  {
    label: 'In Review',
    id: 'ASSIGNED_IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Disputed',
    id: 'ASSIGNED_DISPUTED',
    color: '#ff7272',
  },
  {
    label: 'Approved',
    id: 'ASSIGNED_APPROVED',
    color: '#59e1ca',
  },
];

export const WORK_ORDER_STATUS_LIST = [
  {
    label: 'MET',
    id: 'MET',
    color: '#59e1ca',
  },
  {
    label: 'Pending',
    id: 'PENDING',
    color: '#ff7272',
  },
  {
    label: 'Delivery Initiated',
    id: 'DELIVERY_INITIATED',
    color: '#72ddff',
  },
];

export const TARGET_STATUS_LIST = [
  {
    label: 'MET',
    id: 'MET',
    color: '#59e1ca',
  },
  {
    label: 'UNALLOCATED',
    id: 'UNALLOCATED',
    color: '#baf9ef',
  },
  {
    label: 'WORK IN PROGRESS',
    id: 'WORK_IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'AT RISK',
    id: 'AT_RISK',
    color: '#ffcf8f',
  },
  {
    label: 'NOT PLANNED',
    id: 'NOT_PLANNED',
    color: '#ff7272',
  },
  {
    label: 'UPCOMING UNPLANNED TARGETS',
    id: 'UPCOMING_UNPLANNED_TARGETS',
    color: '#72ddff',
  },
];

export const UNIT_TYPES = {
  KG: { displayText: 'Kg' },
  TONNES: { displayText: 'T' },
  QTY: { displayText: 'Kg' },
};

export const LOCATION_TYPE_LIST = [
  {
    label: 'Dump yard',
    id: 'Dump Yard',
    value: 'Dump Yard',
  },
  {
    label: 'Material Recovery Facility',
    id: 'Material Recovery Facility',
    value: 'Material Recovery Facility',
  },
  {
    label: 'Aggregator',
    id: 'Aggregator',
    value: 'Aggregator',
  },
  {
    label: 'Kabadiwala',
    id: 'Kabadiwala',
    value: 'Kabadiwala',
  },
];

export const LOCATION_TYPE_LIST_NON_RECYCLABLE_PLASTIC = [
  {
    label: 'Dump yard',
    id: 'Dump Yard',
    value: 'Dump Yard',
  },
  {
    label: 'Material Recovery Facility',
    id: 'Material Recovery Facility',
    value: 'Material Recovery Facility',
  },
];

export const Permission = {
  CLIENT_WORK_ORDERS: 'CLIENT_WORK_ORDERS',
  ASSIGN_DELIVERY_TO_INCOMING_WORK_ORDERS: 'ASSIGN_DELIVERY_TO_INCOMING_WORK_ORDERS',
  UPLOAD_DOCUMENTS_AGAINST_DELIVERY: 'UPLOAD_DOCUMENTS_AGAINST_DELIVERY',
  VIEW_DELIVERY: 'VIEW_DELIVERY',
  EXECUTION: 'EXECUTION',
  CREATE_DELIVERY: 'CREATE_DELIVERY',
  DISPUTE_DELIVERY: 'DISPUTE_DELIVERY',
  PROFILE: 'PROFILE',
  APPROVE_DELIVERY: 'APPROVE_DELIVERY',
  ROLES: 'ROLES',
  DASHBOARD: 'DASHBOARD',
  APPROVE_DISPUTE_DOCUMENTS: 'APPROVE/DISPUTE_DOCUMENTS',
  TARGETS: 'TARGETS',
  SERVICE_PROVIDERS: 'SERVICE_PROVIDERS',
  WORK_ORDERS: 'WORK_ORDERS',
  USERS: 'USERS',
  CLIENTS: 'CLIENTS',
  REPORT: 'REPORT',
  FINANCE: 'FINANCE',
  SUPERUSER: 'SUPERUSER',
};

export const AUTHORITY = {
  USER: 'ROLE_USER',
  SERVICE_PROVIDER: 'ROLE_SERVICE_PROVIDER',
  BRAND: 'ROLE_BRAND',
  PRO: 'ROLE_PRO',
  ADMIN: 'ROLE_EPR_ADMIN',
  SUPERUSER: 'ROLE_SUPERUSER',
};

export const AUTHORITY2 = {
  USER: 'ROLE_EPR_USER',
  SERVICE_PROVIDER: 'ROLE_EPR_SERVICE_PROVIDER',
  BRAND: 'ROLE_EPR_BRAND',
  PRO: 'ROLE_EPR_PRO',
  ADMIN: 'ROLE_EPR_PRO_ADMINSTRATOR',
  SUPERUSER: 'ROLE_EPR_PRO_ADMINSTRATOR',
};

export const FCM_ERROR_CODE = {
  PERMISSION_BLOCKED: 'messaging/permission-blocked',
};

export const eventIconMap = {
  DOCUMENT: 'fa-file-text',
  DELIVERY: 'fa-truck fa-flip-horizontal',
  WORK_ORDER: 'fa-cube',
  TARGET: 'fa-dot-circle-o',
  ROLE: 'fa-shield',
  USER: 'fa-user',
  BUSINESS_PROFILE: 'fa-briefcase',
  USER_PROFILE: 'fa-user',
  SERVICE_PROVIDER: 'fa-user',
};

export const ENTITY = {
  DOCUMENT: 'DOCUMENT',
  DELIVERY: 'DELIVERY',
  WORK_ORDER: 'WORK_ORDER',
  TARGET: 'TARGET',
  ROLE: 'ROLE',
  USER: 'USER',
  REPORT: 'REPORT',
  BUSINESS_PROFILE: 'BUSINESS_PROFILE',
  USER_PROFILE: 'USER_PROFILE',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
};

export const EX_SUMMARY_VIEW = {
  FY: 'FY',
  TILL_MONTH: 'TILL_MONTH',
};

export const APP_EVENTS = {
  topic: {
    DELIVERY: 'DELIVERY',
    SERVER_ERROR: 'SERVER_ERROR',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    ULB: 'ULB',
  },
  event: {
    CHANGE_DELIVERY: 'CHANGE_DELIVERY',
    SERVER_DOWN_503: 'SERVER_DOWN_503',
    SHOW_PRIVACY_MODAL: 'SHOW_PRIVACY_MODAL',
    LOAD_ULB: 'LOAD_ULB',
  },
};

export const INVOICE_STATUS_LIST_SP = [
  {
    label: 'Draft',
    value: 'DRAFT',
    id: true,
  },
  {
    label: 'Outstanding',
    value: 'OUTSTANDING',
    id: 'OUTSTANDING',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
    id: 'APPROVED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
    id: 'REJECTED',
  },
  {
    label: 'Accounted',
    value: 'ACCOUNTED',
    id: 'ACCOUNTED',
  },
  {
    label: 'Partially Paid',
    value: 'PARTIALLY_PAID',
    id: 'PARTIALLY_PAID',
  },
  {
    label: 'Paid',
    value: 'PAID',
    id: 'PAID',
  },
];

export const INVOICE_STATUS_LIST_PRO = [
  {
    label: 'Outstanding',
    value: 'OUTSTANDING',
    id: 'OUTSTANDING',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
    id: 'APPROVED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
    id: 'REJECTED',
  },

  {
    label: 'Accounted',
    value: 'ACCOUNTED',
    id: 'ACCOUNTED',
  },
  {
    label: 'Partially Paid',
    value: 'PARTIALLY_PAID',
    id: 'PARTIALLY_PAID',
  },
  {
    label: 'Paid',
    value: 'PAID',
    id: 'PAID',
  },
];

export const PAYMENT_MODE_LIST = [
  { id: 'NET_BANKING', title: 'Net Banking', value: 'NET_BANKING' },
  { id: 'CHEQUE', title: 'Cheque', value: 'CHEQUE' },
  { id: 'CASH', title: 'Cash', value: 'CASH' },
  { id: 'UPI_WALLET', title: 'UPI/Wallet', value: 'UPI_WALLET' },
];

export const PLANNING_ITEM_TABS_TYPES = [
  { id: 1, label: 'Plastics', value: 'plasticTab' },
  // { id: 2, label: 'E-waste', value: 'eWasteTab' },
  // { id: 3, label: 'Industrial waste', value: 'industrialWasteTab' },
  // { id: 4, label: 'Tyres', value: 'tyresTab' },
];

export const PLANNING_ITEM_CARDS_TYPES = [
  {
    id: 1,
    label: 'Unplanned Targets',
    value: 0,
    unit: 'MT',
    cardPlanningValue: 'unplanned',
    planningHeaderQty: 'unplannedTargets',
  },
  {
    id: 2,
    label: 'Draft WO Quantity',
    value: 0,
    unit: 'MT',
    cardPlanningValue: 'draftWos',
    planningHeaderQty: 'draftWOQuantity',
  },
  {
    id: 3,
    label: 'Planned Targets',
    value: 0,
    unit: 'MT',
    cardPlanningValue: 'planned',
    planningHeaderQty: 'plannedTargets',
  },
  {
    id: 4,
    label: 'Avail. For Replanning',
    value: 0,
    unit: 'MT',
    cardPlanningValue: 'availForReplanning',
    planningHeaderQty: 'availForReplanning',
  },
  // { id: 5, label: 'Capacity Gap', value: 0, unit: 'MT', cardPlanningValue: 'capacityGap' },
];

export const MARKETPLACE_ITEM_CARDS_TYPES = [
  { id: 1, label: 'Total Annual Credits', value: 0 },
  { id: 2, label: 'Total WOs', value: 0 },
  { id: 3, label: 'Available Credits', value: 0 },
];

export const PLANNING_ITEM_NAV_TYPES = [
  { id: 1, label: 'Unplanned', value: 'unplanned' },
  { id: 2, label: 'Draft WOs', value: 'draftWos' },
  { id: 3, label: 'Planned', value: 'planned' },
  { id: 4, label: 'Avail. for Replanning', value: 'availForReplanning' },
  // { id: 5, label: 'Capacity Gap', value: 'capacityGap' },
];

export const UNPLANNED_TAB_TYPES = [
  { id: 1, label: 'Brandwise Targets', value: 'brandwiseTargets' },
  // { id: 2, label: 'Itemwise Targets', value: 'itemwiseTargets' },
];

export const UNPLANNED_BRAND_CARDS = [
  { id: 1, label: 'Overall Margin', value: 0, unit: '%' },
  { id: 2, label: 'Total Revenue', value: 0, unit: 'Lac' },
  { id: 3, label: 'Capacity Gap', value: 0, unit: 'MT' },
];

export const BRAND_PLANNING_TABS = [
  { id: 1, label: 'Unplanned', value: 'Unplanned' },
  { id: 2, label: 'Planned', value: 'Planned' },
];

export const VENDOR_SUPPLY_TABS = [
  { id: 1, label: 'Recyclers', value: 'recyclers' },
  { id: 2, label: 'Service Providers', value: 'serviceProviders' },
];

export const INVOICE_TAX_LIST = [
  { id: 5, title: '5', value: 5 },
  { id: 12, title: '12', value: 12 },
  { id: 18, title: '18', value: 18 },
];

export const FULFILLMENT_CYCLE_LIST = [
  { id: 1, label: 'MONTHLY', value: 1 },
  { id: 2, label: 'QUARTERLY', value: 3 },
  { id: 3, label: 'SEMIANNUALLY', value: 6 },
  { id: 4, label: 'ANNUALLY', value: 12 },
];

export const DIRECTION = {
  RECEIVED: 'RECEIVED',
  ASSIGNED: 'ASSIGNED',
};

export const ENDORSEMENT_STATUS = {
  IN_REVIEW: { id: 'IN_REVIEW', displayText: 'Review', colors: 'red' },
  APPROVED: { id: 'APPROVED', displayText: 'Accepted', colors: 'green' },
  TO_BE_ASSIGNED: { id: 'TO_BE_ASSIGNED', displayText: 'Pending', colors: 'orange' },
  TO_BE_SIGNED: {
    id: 'TO_BE_SIGNED',
    displayText: 'Waiting to be signed',
    colors: 'yellow',
  },
  GENERATE: { id: 'GENERATE', displayText: 'Generate', colors: 'blue' },
  REJECTED: { id: 'REJECTED', displayText: 'Rejected', colors: 'red' },
};

export const SP_ULB_DOC_STATUS = [
  { id: 'TO_BE_SIGNED', displayText: 'Pending', colors: 'red' },
  { id: 'IN_REVIEW', displayText: 'Submitted', colors: 'red' },
];

export const EWASTEMANDATORYFIELDS = [
  {
    fieldName: 'pickUpInvoiceNumber',
    type: Yup.string()
      // .matches(/^[A-za-z0-9]+$/, 'invalid number')
      .required(),
  },
  {
    fieldName: 'invoiceDate',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceValue',
    type: Yup.number().required(),
  },
  {
    fieldName: 'invoiceState',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceCity',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceItemCode',
    type: Yup.string()
      // .matches(/^[A-za-z0-9]+$/, 'invalid number')
      .required(),
  },
  {
    fieldName: 'invoiceUnitRate',
    type: Yup.number().required(),
  },
  {
    fieldName: 'invoiceAmount',
    type: Yup.number().required(),
  },
  {
    fieldName: 'invoiceGstAmount',
    type: Yup.number().required(),
  },
  {
    fieldName: 'invoiceUnit',
    type: Yup.string()
      // .matches(/^[A-Za-z]+$/, 'invalid number')
      .required(),
  },
  {
    fieldName: 'eWayBillFromLocationState',
    type: Yup.string().required(),
  },
  {
    fieldName: 'eWayBillFromLocationCity',
    type: Yup.string().required(),
  },
  {
    fieldName: 'eWayBillToLocationState',
    type: Yup.string().required(),
  },
  {
    fieldName: 'eWayBillToLocationCity',
    type: Yup.string().required(),
  },
  {
    fieldName: 'eWayBillDate',
    type: Yup.string().required(),
  },
  {
    fieldName: 'form6Weight',
    type: Yup.string()
      // 1.23 Kg
      // .matches(/^[0-9aA-zZ\s\.]+$/, 'invalid number')
      .matches(/^[^;',~`!@#$%^&*()_+\-{}|:"<>?=\[\]\\\/]+$/g, 'invalid number')
      .required(),
  },
  {
    fieldName: 'invoicePurchaseOrderId',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceProductDescription',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceProductCode',
    type: Yup.string().required(),
  },
  {
    fieldName: 'brandId',
    type: Yup.string().required(),
  },
  {
    fieldName: 'pickUpWeighBridgeDate',
    type: Yup.string().required(),
  },
  {
    fieldName: 'taxRate',
    type: Yup.string().matches('^([1-9]([0-9])?|0)(.[0-9]{1,2})?$', 'Invalid percentage'),
  },
  {
    fieldName: 'eWayBillValidity',
    type: Yup.string().required(),
  },
  {
    fieldName: 'form6Number',
    type: Yup.string().required(),
  },
  {
    fieldName: 'pickUpWeighTime',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceProductCode',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceProductDescription',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoicePurchaseOrderId',
    type: Yup.string().required(),
  },
  {
    fieldName: 'pickUpVehicleNumber',
    type: Yup.string()
      .matches(/^[^;',.~`!@#$%^&*()_+\-{}|:"<>?=\[\]\\\/]+$/g, 'invalid number')
      .required(),
  },
];
export const CATEGORY_TAB_TYPES = [
  { id: 1, label: 'Plastic', value: 'Plastic' },
  // { id: 2, label: 'E-Waste', value: 'E-Waste' },
  // { id: 3, label: 'Industrial waste', value: 'industrialWasteTab' },
  // { id: 4, label: 'Tyres', value: 'Tyres' },
  // { id: 5, label: 'Battery', value: 'Battery' },
];

export const SOURCES_APPCODE_MAP = {
  EPR005: 'EPR',
  RPL009: 'Recycler Portal',
};

export const EWASTEMANDATORYFIELDSFORALLBRANDS = {
  brandId: Yup.string().required('Select Brand'),
  invoiceValue: Yup.number().required(),
  invoiceDate: Yup.string().required(),
  pickUpWeighBridgeDate: Yup.string().required(),
  taxRate: Yup.string().matches('^([1-9]([0-9])?|0)(.[0-9]{1,2})?$', 'Invalid percentage'),
  eWayBillValidity: Yup.string().required(),
  pickUpWeighTime: Yup.string().required(),
  pickUpVehicleNumber: Yup.string()
    .matches(/^[^;',.~`!@#$%^&*()_+\-{}|:"<>?=\[\]\\\/]+$/g, 'invalid number')
    .required('Select Vehicle Number'),
};

export const FEATURE_LIST = [
  { id: 1, label: 'Work Order Id', value: 1 },
  { id: 2, label: 'Delivery Id', value: 2 },
  { id: 3, label: 'Invoice Id', value: 3 },
];
export const PRIVILEGE_LIST_FOR_WO = [
  {
    id: 1,
    label: 'Change Price',
    value: 1,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'change_price_in_work_order',
      },
    ],
  },
];
export const PRIVILEGE_LIST_FOR_DL = [
  {
    id: 1,
    label: 'Status',
    value: 1,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'change_delivery_status',
      },
    ],
  },
  {
    id: 2,
    label: 'Attribution Month',
    value: 2,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'change_attribution_month',
      },
    ],
  },
  {
    id: 3,
    label: 'Delete Documents',
    value: 3,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'delete_document',
      },
    ],
  },
];
export const PRIVILEGE_LIST_FOR_INVOICE = [
  {
    id: 1,
    label: 'De-Link Brand Invoice',
    value: 1,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'delink_invoice',
      },
    ],
  },
];

export const reportArray = [
  {
    id: 1,
    label: 'Summary Report',
    value: 'summary_report',
  },
  {
    id: 2,
    label: 'Allocated Report',
    value: 'allocated_report',
  },
  {
    id: 3,
    label: 'Un Allocated Report',
    value: 'unallocated_report',
  },
];

export const CUSTOMER_TYPE_OPTIONS = [
  { id: 0, label: 'EPR', value: 'epr' },
  { id: 1, label: 'NON_EPR', value: 'non-epr' },
];

export const ENTITY_TYPE_OPTIONS = [
  { id: 0, label: 'Brand Owner', value: 'brand owner' },
  { id: 1, label: 'Importer', value: 'importer' },
  { id: 2, label: 'Producer', value: 'producer' },
];

export const TRACEABILITY_DOCS_OPTIONS = [
  { id: 0, label: 'Yes', value: true },
  { id: 1, label: 'No', value: false },
];

export const PROCESSING_TYPE_OPTIONS = [
  { id: 0, label: 'Recycling', value: 'RECYCLING' },
  { id: 1, label: 'End of life', value: 'END_OF_LIFE' },
];

export const BUSINESS_TYPE_OPTIONS = [
  { id: 'DIRECT', label: 'Direct Business' },
  { id: 'THROUGH_SERVICE_PROVIDER', label: 'Through Service Provider' },
];

export const MANDATORY_DOCUMENTS = [
  'DROP_CERTIFICATE_OF_RECYCLING',
  'DROP_RECYCLER_CERTIFICATE',
  'DROP_CERTIFICATE_OF_CPCB',
  'DROP_CO_PROCESSING_CERTIFICATE',
];

export const HSN_OPTIONS = [
  { id: 0, label: 'SAC', value: true },
  { id: 1, label: 'HSN', value: false },
];

export const SAC = 'sac';

export const browseNodeIdList = [
  { id: 1, nodeId: 1 },
  { id: 2, nodeId: 2 },
  { id: 3, nodeId: 3 },
  { id: 4, nodeId: 4 },
  { id: 5, nodeId: 5 },
  { id: 6, nodeId: 6 },
];

export const CREDIT_EXECUTION_TYPES = [
  { id: 1, label: 'SELF', value: 'DIRECT' },
  { id: 2, label: 'SERVICE_PROVIDER', value: 'THROUGH_SERVICE_PROVIDER' },
  { id: 3, label: 'BOTH', value: 'BOTH' },
];

export const INVOICE_METRICS = {
  'Total invoiced Amount': 62400,
  'Manual invoiced Amount': 35000,
  'Credit Note': 46700,
  'Debit Note': 1280,
  'Payment Received': 78966,
  'Payment Pending': 45000,
};

export const groupedOptions = [
  {
    type: 'businessNames',
    value: 'businessNames',
    title: 'Search Business Name',
    label: 'Search Business Name',
    placeholder: 'Enter Business Name',
    id: 'businessNames',
  },
  {
    type: 'panNos',
    value: 'panNos',
    title: 'Search PAN',
    label: 'Search PAN',
    placeholder: 'Enter PAN',
    id: 'pan.contains',
  },
  {
    type: 'contactPersons',
    value: 'contactPersons',
    title: 'Search Contact Person',
    label: 'Search Contact Person',
    placeholder: 'Enter Contact Person Name',
    id: 'contactPersonName.equals',
  },
  {
    type: 'phoneNumbers',
    value: 'phoneNumbers',
    title: 'Search Phone Number',
    label: 'Search Phone Number',
    placeholder: 'Enter Phone Number',
    id: 'mobile.equals',
  },
  {
    type: 'emails',
    value: 'emails',
    title: 'Search Email',
    label: 'Search Email',
    placeholder: 'Enter Email Id',
    id: 'email.equals',
  },
];
export const INVOICES_TYPES_MAP = {
  'ADVANCED INVOICE': 'Advance',
  'REGULAR INVOICE': 'Regular',
};

export const DELIVERY_INVOICE_STATUS_MAP = {
  'INVOICE MAPPED': 'Invoice mapped',
  'PROFORMA GENERATED': 'Profroma Generated',
  'INVOICE GENERATED': 'Invoice Generated',
};

export const VerticalOptions = [
  { label: 'PLASTIC', id: 0, value: 'Plastic' },
  { label: 'E_WASTE', id: 1, value: 'E-waste' },
  { label: 'TYRES', id: 2, value: 'Tyres' },
  { label: 'BATTERIES', id: 3, value: 'Batteries' },
];

export const WITH_EWAY_BILL_DOCS = [
  'PICKUP_E_WAY_BILL',
  'PICKUP_WB',
  'DROP_OFF_WB',
  'PICKUP_INVOICE',
];

export const WITHOUT_EWAY_BILL_DOCS = ['PICKUP_WB', 'DROP_OFF_WB', 'PICKUP_INVOICE'];

export const DOC_CATEGORY_BULK = [
  {
    type: 'SELECT',
    title: 'Select Name',
    required: false,
  },
  {
    type: 'PICKUP_WB',
    title: 'Pickup Weighbridge',
    required: true,
  },
  {
    type: 'PICKUP_E_WAY_BILL',
    title: 'E-Way Bill',
    required: true,
  },
  {
    type: 'PICKUP_INVOICE',
    title: 'Invoice',
    required: true,
  },
  {
    type: 'PICKUP_LR',
    title: 'Lorry Receipt',
    required: true,
  },
  {
    type: 'PICKUP_OTHERS',
    title: 'Others',
    required: true,
  },
  {
    type: 'DROP_OFF_WB',
    title: 'Dropoff Weighbridge',
    required: true,
  },
];

export const DOC_TYPE_MAP_BULK = {
  PICKUP_WB: 'Pickup Weighbridge',
  PICKUP_INVOICE: 'Invoice',
  DROP_OFF_WB: 'Dropoff Weighbridge',
  PICKUP_LR: 'Lorry Receipt',
  PICKUP_OTHERS: 'Others',
  PICKUP_E_WAY_BILL: 'E-Way Bill',
};

export const DISPUTE_REASON_OPTIONS = [
  {
    label: 'Document Missing',
    id: 1,
    value: 'Document Missing',
  },
  {
    label: 'Document Mismatch',
    id: 2,
    value: 'Document Mismatch',
  },
  {
    label: 'Other',
    id: 3,
    value: null,
  },
];
